import VerticalSlider from "../../containers/VerticalSlider";

export const casinoGamesData = {
  indianCard: {
    TeenPatti3Card: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/TeenPatti.jpg",
      alt: "teen patti 3 card",
      name: "teen patti 3 card",
      providerName: "Ezugi",
    },
    BetOnTeenPatti: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/TeenPatti20-20.jpg",
      alt: "bet on teen patti",
      name: "bet on teen patti",
      providerName: "Ezugi",
    },
    AndarBahar: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-andar-bahar",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/AndarBahar.jpg",
      alt: "andar bahar",
      name: "andar bahar",
      providerName: "Ezugi",
    },
    Cards32: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-32-cards",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/32Cards.jpg",
      alt: "32 cards",
      name: "32 cards",
      providerName: "Ezugi",
    },
    SpanishRoulette: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-spanish-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/Roulette.jpg",
      alt: "spanish roulette",
      name: "spanish roulette",
      providerName: "Ezugi",
    },
    CricketWar: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-cricket-war",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/Cricketwar.jpg",
      alt: "cricket war",
      name: "cricket war",
      providerName: "Ezugi",
    },
    SicBo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-sic-bo",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/Sicbo.jpg",
      alt: "sic bo",
      name: "sic bo",
      providerName: "Ezugi",
    },
    Baccarat: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-baccarat",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/Baccarat.jpg",
      alt: "baccarat",
      name: "baccarat",
      providerName: "Ezugi",
    },
    DragonTiger: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-dragon-tiger",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/DragonTiger.jpg",
      alt: "dragon tiger",
      name: "dragon tiger",
      providerName: "Ezugi",
    },
    OneDayTteenPatti: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/OneDayTeenPatti.jpg",
      alt: "one day teen patti",
      name: "one day teen patti",
      providerName: "Ezugi",
    },
    Lucky7: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-lucky-7",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/Lucky7.jpg",
      alt: "lucky 7",
      name: "lucky 7",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-ultimate-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/indian-card/UltimateRoulette.jpg",
      alt: "UltimateRoulette",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
  },

  popularGames: {
    AndarAaharVivo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/vivo-andar-bahar",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/vivoAndarBahar.jpg",
      alt: "andar bahar",
      name: "andar bahar",
      providerName: "Ezugi",
    },
    TeenPattiVivo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/vivo-teen-patti",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/vivoTeenPatti.jpg",
      alt: "teen patti",
      name: "teen patti",
      providerName: "Ezugi",
    },
    DragonTigerVivo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/vivo-dragon-tiger",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/vivo-dragon-tiger.jpg",
      alt: "dragon tiger",
      name: "dragon tiger",
      providerName: "Ezugi",
    },
    LasVegasBlackjackVivo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/vivo-las-vegas-blackjack.jpg",
      alt: "las vegas blackjack",
      name: "las vegas blackjack",
      providerName: "Ezugi",
    },
    LimitlessBlackjackVivo: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/vivo-limitless-blackjack",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/vivo-limitless-blackjack.jpg",
      alt: "limitless blackjack",
      name: "limitless blackjack",
      providerName: "Ezugi",
    },
    GonzosQuestMegaways: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/GonzosTreasureHunt.jpg",
      alt: "ezgrt gonzos quest megaways",
      name: "ezgrt gonzos quest megaways",
      providerName: "Ezugi",
    },
    CasinoMarinaAndarBahar: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/OttAndarBahar.jpg",
      alt: "casino marina andar bahar",
      name: "casino marina andar bahar",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-auto-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/Auto-Roulette.jpg",
      alt: "auto roulette",
      name: "auto roulette",
      providerName: "Ezugi",
    },
    BbetOnTeenPatti: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/BetOnTeenPatti.jpg",
      alt: "bet on teen patti",
      name: "bet on teen patti",
      providerName: "Ezugi",
    },
    AndarBahar: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-andar-bahar",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/AndarBahar.jpg",
      alt: "andar bahar",
      name: "andar bahar",
      providerName: "Ezugi",
    },
    Baccarat: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-baccarat",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/Baccarat.jpg",
      alt: "baccarat",
      name: "baccarat",
      providerName: "Ezugi",
    },
    FirstPersonMegaBall: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezgevo-first-person-mega-ball",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/popular/MegaBall.jpg",
      alt: "ezgevo first person mega ball",
      name: "ezgevo first person mega ball",
      providerName: "Ezugi",
    },
  },

  rightBarGames: {
    BaccaratRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezugi/Baccarat",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/baccarat.jpg",
      alt: "baccarat",
      name: "baccarat",
      providerName: "Ezugi",
    },
    AndarBaharRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-andar-bahar",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/andar-bahar.jpg",
      alt: "andar bahar",
      name: "andar bahar",
      providerName: "Ezugi",
    },
    SicBoRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-sic-bo",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/sic-bo.jpg",
      alt: "sic bo",
      name: "sic bo",
      providerName: "Ezugi",
    },
    SpeedRouletteRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-speed-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/speed-roulette.jpg",
      alt: "speed roulette",
      name: "speed roulette",
      providerName: "Ezugi",
    },
    BlackJackRB: {
      code: "",
      casino: "XPG",
      provider: "WorldCasino",
      redirectUrl: "/casino/xpg-black-jack",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/blackJack.jpg",
      alt: "black jack",
      name: "black jack",
      providerName: "Ezugi",
    },
    ItalianRouletteRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-italian-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/italian-roulette.jpg",
      alt: "italian roulette",
      name: "italian roulette",
      providerName: "Ezugi",
    },
    NamasteRouletteRB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-namaste-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/namaste-roulette.jpg",
      alt: "namaste roulette",
      name: "namaste roulette",
      providerName: "Ezugi",
    },
    Lucky7RB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-lucky-7",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/lucky7.jpg",
      alt: "lucky 7",
      name: "lucky 7",
      providerName: "Ezugi",
    },
    Cards32RB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-32-cards",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/cards32.jpg",
      alt: "32 cards",
      name: "32 cards",
      providerName: "Ezugi",
    },
    Cards32RB: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-ultimate-roulette",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/rightbar/cards32.jpg",
      alt: "Ultimate Roulette",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
  },

  verticalSlider: {
    AviatorVS: {
      code: "",
      casino: "Spribe",
      provider: "Spribe",
      redirectUrl: "/casino/spribe/aviator",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/VSlider/aviator.jpg",
      alt: "aviator",
      name: "aviator",
      providerName: "Spribe",
    },
    OneDayTeenPattiVS: {
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/VSlider/one-day-teen-patti.jpg",
      alt: "one day teen patti",
      name: "one day teen patti",
      providerName: "Ezugi",
    },
    SpeedVipBlackjackAVS: {
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-a",
      homeUrl: "/sports",
      imgUrl: "./images/casino-games/VSlider/speed-vip-blackjack-a.jpg",
      alt: "speed vip blackjack a",
      name: "speed vip blackjack a",
      providerName: "Ezugi",
    },
  },
};
