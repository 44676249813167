import React from "react";
import Slider from "react-slick";
import { casinoGamesData } from "../lib/data/casinoGames.data";

const VerticalSlider = () => {
  const totalGames = {
    ...casinoGamesData?.verticalSlider,
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  return (
    <div className="VSlider">
      <Slider {...settings}>
        {Object.values(totalGames).map((item) => (
          <div>
            <div key={item.code} className="slide_box">
              <a href={item.redirectUrl}>
                <img src={item.imgUrl} alt={item.alt} />
                <div className="overlay"></div>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VerticalSlider;
