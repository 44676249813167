import PlayBtn from "../../assets/images/play-btn.png";

export const slotsGames = {
  AsgardianStones: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-asgardian-stones",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/asgardianstones.jpg",
    alt: "asgardian stones",
    name: "asgardian stones",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SecretsOfAtlantis: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-secrets-of-atlantis",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/secrets-of-atlantis.jpg",
    alt: "secrets of atlantis",
    name: "secrets of atlantis",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GrandWheel: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-grand-wheel",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/grand-wheel.jpg",
    alt: "grand wheel",
    name: "grand wheel",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Strike777: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-777-strike",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/777strike.jpg",
    alt: "777 strike",
    name: "777 strike",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Nights10001: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-10001-nights",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/10001-nights.jpg",
    alt: "10001 nights",
    name: "10001 nights",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  CashUltimate: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-cash-ultimate",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/cash_ultimate.jpg",
    alt: "cash ultimate",
    name: "cash ultimate",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  CashVolt: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-cash-volt",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/cash_volt.jpg",
    alt: "cash volt",
    name: "cash volt",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DazzleMeMegaways: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dazzle-me-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/dazzle_me_megaways.jpg",
    alt: "dazzle me megaways",
    name: "dazzle me megaways",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DivineFortuneMegaways: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-divine-fortune-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/divine_fortune_megaways.jpg",
    alt: "divine fortune megaways",
    name: "divine fortune megaways",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DynamiteRiches: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dynamite-riches",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/dynamite_riches.jpg",
    alt: "dynamite riches",
    name: "dynamite riches",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FortuneHouse: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-fortune-house",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/fortunehouse.jpg",
    alt: "fortune house",
    name: "fortune house",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FruitShopMegaways: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-fruit-shop-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Fruit_Shop_Megaways.jpg",
    alt: "fruit shop megaways",
    name: "fruit shop megaways",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GoldenCryptex: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-golden-cryptex",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Golden_Cryptex.jpg",
    alt: "golden cryptex",
    name: "golden cryptex",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GoldenTsar: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-golden-tsar",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Golden_Tsar.jpg",
    alt: "golden tsar",
    name: "golden tsar",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GorillaKingdom: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-gorilla-kingdom",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gorilla_Kingdom.jpg",
    alt: "gorilla kingdom",
    name: "gorilla kingdom",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  MysteryReelsMegaways: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mystery-reels-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mystery_Reels_Megaways.jpg",
    alt: "mystery reels megaways",
    name: "mystery reels megaways",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  RageOfTheSeas: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-rage-of-the-seas",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Rage_Of_The_Seas.jpg",
    alt: "rage of the seas",
    name: "rage of the seas",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ReelKingMega: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-reel-king-mega",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Reel_King_Mega.jpg",
    alt: "reel king mega",
    name: "reel king mega",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  TurnYourFortune: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-turn-your-fortune",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Turn_Your_Fortune.jpg",
    alt: "turn your fortune",
    name: "turn your fortune",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  TwinSpinMegaways: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-twin-spin-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Twin_Spin_Megaways.jpg",
    alt: "twin spin megaways",
    name: "twin spin megaways",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  WillysHotChillies: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-willys-hot-chillies",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Willys_Hot_Chillies.jpg",
    alt: "willys hot chillies",
    name: "willys hot chillies",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ChineseTreasures: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-chinese-treasures",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Chinese-Treasures.jpg",
    alt: "chinese treasures",
    name: "chinese treasures",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DragonsLuck: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dragons-luck",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dragon's-Luck.jpg",
    alt: "dragons luck",
    name: "dragons luck",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DragonsFire: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dragons-fire",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dragon's-Fire.jpg",
    alt: "dragons fire",
    name: "dragons fire",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DragonsLuckDeluxe: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dragons-luck-deluxe",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dragon's-Luck-Deluxe.jpg",
    alt: "dragons luck deluxe",
    name: "dragons luck deluxe",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DragonsLuckPowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dragon's-Luck-Power-Reels.jpg",
    alt: "dragons luck power reels",
    name: "dragons luck power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  EpicJourney: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-epic-journey",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Epic-Journey.jpg",
    alt: "epic journey",
    name: "epic journey",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GodOfWealth: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-god-of-wealth",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/God-Of-Wealth.jpg",
    alt: "god of wealth",
    name: "god of wealth",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LuckyFortuneCat: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-lucky-fortune-cat",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Lucky-Fortune-Cat.jpg",
    alt: "lucky fortune cat",
    name: "lucky fortune cat",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MagicGate: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-magic-gate",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Magic-Gate.jpg",
    alt: "magic gate",
    name: "magic gate",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GoldenTemple: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-golden-temple",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Golden-Temple.jpg",
    alt: "golden temple",
    name: "golden temple",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  PhoenixFirePowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-phoenix-fire-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Phoenix-Fire-Power-Reels.jpg",
    alt: "phoenix fire power reels",
    name: "phoenix fire power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GoldenOffer: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-golden-offer",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Golden-Offer.jpg",
    alt: "golden offer",
    name: "golden offer",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RasLegend: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-ras-legend",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/RA's-Legend.jpg",
    alt: "ras legend",
    name: "ras legend",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GonzosQuestMegaways: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gonzo's-Quest-Megaways.jpg",
    alt: "gonzos quest megaways",
    name: "gonzos quest megaways",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RainbowJackpots: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-rainbow-jackpots",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Rainbow-Jackpots.jpg",
    alt: "rainbow jackpots",
    name: "rainbow jackpots",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MegaPyramid: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mega-pyramid",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mega-Pyramid.jpg",
    alt: "mega pyramid",
    name: "mega pyramid",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  JimiHendrixOnlineSlot: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-jimihendrixonlineslot",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jimi_Hendrix.jpg",
    alt: "jimi hendrix online slot",
    name: "jimi hendrix online slot",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Jumanji: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-jumanji",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jumanji.jpg",
    alt: "jumanji",
    name: "jumanji",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  MotorheadVideoSlot: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-motorheadvideoslot",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Motorhead_Video_Slot.jpg",
    alt: "motorhead video slot",
    name: "motorhead video slot",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Starburst: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-starburst",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Starburst.jpg",
    alt: "starburst",
    name: "starburst",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  WarOfGods: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-war-of-gods",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/War-Of-Gods.jpg",
    alt: "war of gods",
    name: "war of gods",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  AlohaChristmas: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-aloha-christmas",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Aloha-Christmas.jpg",
    alt: "aloha christmas",
    name: "aloha christmas",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  AlohaClusterPays: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-aloha-cluster-pays",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Aloha-Cluster-Pays.jpg",
    alt: "aloha cluster pays",
    name: "aloha cluster pays",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  AncientsBlessing: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-ancients-blessing",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Ancients-Blessing.jpg",
    alt: "ancients blessing",
    name: "ancients blessing",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  AurumCodex: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-aurum-codex",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Aurum-Codex.jpg",
    alt: "aurum codex",
    name: "aurum codex",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GunsNRosesVideoSlots: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-gunsnrosesvideoslots",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Guns-N-Roses.jpg",
    alt: "guns n' roses video slots",
    name: "guns n' roses video slots",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  BettyBorisAndBoo: {
    code: "",
    casino: "Red Tiger",
    provider: "WorldCasino",
    redirectUrl: "/casino/qt-betty-boris-and-boo",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Betty-Boris-and-Boo.jpg",
    alt: "betty boris and boo",
    name: "betty boris and boo",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WildWildWestTheGreatTrainHeist: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-Wild-West-The-Great-Train-Heist.jpg",
    alt: "wild wild west: the great train heist",
    name: "wild wild west: the great train heist",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SteamTower: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-steam-tower",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Steam-Tower.jpg",
    alt: "steam tower",
    name: "steam tower",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ZzeusLightningPowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-zeus-lightning-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Zeus-Lightning-Power-Reels.jpg",
    alt: "zeus lightning power reels",
    name: "zeus lightning power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WingsOfRa: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-wings-of-ra",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wings-of-Ra.jpg",
    alt: "wings of ra",
    name: "wings of ra",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  Trillionaire: {
    code: "",
    casino: "Ezugi",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-trillionaire",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Trillionaire.jpg",
    alt: "trillionaire",
    name: "trillionaire",
    providerName: "Ezugi",
    playImg: PlayBtn,
  },
  TreasureMine: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-treasure-mine",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Treasure-Mine.jpg",
    alt: "treasure mine",
    name: "treasure mine",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ThorsVengeance: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-thors-vengeance",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Thor's-Vengeance.jpg",
    alt: "thors vengeance",
    name: "thors vengeance",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RegalStreak: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-regal-streak",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Regal-Streak.jpg",
    alt: "regal streak",
    name: "regal streak",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RegalBeasts: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-regal-beasts",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Regal-Beasts.jpg",
    alt: "regal beasts",
    name: "regal beasts",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ReelKeeper: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-reel-keeper",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Reel-Keeper.jpg",
    alt: "reel keeper",
    name: "reel keeper",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RainbowJackpotsPowerLines: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-rainbow-jackpots-power-lines",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Rainbow-Jackpots-Power-Lines.jpg",
    alt: "rainbow jackpots-power-lines",
    name: "rainbow jackpots-power-lines",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  PiggyRichesMegaways: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-piggy-riches-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Piggy-Riches-Megaways.jpg",
    alt: "piggy riches-megaways",
    name: "piggy riches-megaways",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  JingleBells: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-jingle-bells",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jingle-Bells.jpg",
    alt: "jingle bells",
    name: "jingle bells",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  JackInAPot: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-jack-in-a-pot",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jack-in-a-Pot.jpg",
    alt: "jack in a pot",
    name: "jack in a pot",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  FiveStar: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-five-star",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Five-Star.jpg",
    alt: "five star",
    name: "five star",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  BountyRaid: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-bounty-raid",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Bounty-Raid.jpg",
    alt: "bounty raid",
    name: "bounty raid",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MysteryReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mystery-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mystery-Reels.jpg",
    alt: "mystery reels",
    name: "mystery reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DiamondBlitz: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-diamond-blitz",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Diamond-Blitz.jpg",
    alt: "diamond blitz",
    name: "diamond blitz",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DragonsFireMegaways: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dragons-fire-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dragon's-Fire-Megaways.jpg",
    alt: "dragons fire megaways",
    name: "dragons fire megaways",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RomeTheGoldenAge: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-rome-the-golden-age",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Rome-The-Golden-Age.jpg",
    alt: "rome the golden age",
    name: "rome the golden age",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ReelRush: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-reel-rush",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Reel-Rush.jpg",
    alt: "reel rush",
    name: "reel rush",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  BloodSuckers: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-blood-suckers",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Blood-Suckers.jpg",
    alt: "blood suckers",
    name: "blood suckers",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  BloodSuckers2: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-blood-suckers-2",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Blood-Suckers-2.jpg",
    alt: "blood suckers 2",
    name: "blood suckers 2",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  PyramidQuestForImmortality: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Pyramid-Quest-for-Immortality.jpg",
    alt: "pyramid quest for immortality",
    name: "pyramid quest for immortality",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ButterflyStaxx: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-butterfly-staxx",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Butterfly-Staxx.jpg",
    alt: "butterfly staxx",
    name: "butterfly staxx",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ButterflyStaxx2: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-butterfly-staxx-2",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Butterfly-Staxx-2.jpg",
    alt: "butterfly staxx 2",
    name: "butterfly staxx 2",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  PiggyRiches: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-piggy-riches",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Piggy-Riches.jpg",
    alt: "piggy riches",
    name: "piggy riches",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DarkKingForbiddenRiches: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dark-King-Forbidden-Riches.jpg",
    alt: "dark king forbidden riches",
    name: "dark king forbidden riches",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  JingleBellsPowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-jingle-bells-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jingle-Bells-Power-Reels.jpg",
    alt: "jingle bells power reels",
    name: "jingle bells power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  JingleSpin: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-jingle-spin",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jingle-Spin.jpg",
    alt: "jingle spin",
    name: "jingle spin",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DazzleMe: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dazzle-me",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dazzle-Me.jpg",
    alt: "dazzle me",
    name: "dazzle me",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  JackHammer: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-jack-hammer",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jack-Hammer.jpg",
    alt: "jack hammer",
    name: "jack hammer",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  JackHammer2FishyBusiness: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jack-Hammer-2-Fishy-Business.jpg",
    alt: "jack hammer 2 fishy business",
    name: "jack hammer 2 fishy business",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DeadOrAlive: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dead-or-alive",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dead-or-Alive.jpg",
    alt: "dead or alive",
    name: "dead or alive",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DeadOrAlive2: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dead-or-alive-2",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dead-or-Alive-2.jpg",
    alt: "dead or alive 2",
    name: "dead or alive 2",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DeadOrAlive2FeatureBuy: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dead-or-Alive-2-Feature-Buy.jpg",
    alt: "dead or alive 2 feature buy",
    name: "dead or alive 2 feature buy",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  JackAndTheBeanstalk: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jack-and-the-Beanstalk.jpg",
    alt: "jack and the beanstalk",
    name: "jack and the beanstalk",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GordonRamsayHellsKitchen: {
    code: "",
    casino: "Ezugi",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-gordonramsayhellskitchen",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gordon_Ramsay_Hells_Kitchen.jpg",
    alt: "gordon ramsay hell’s kitchen",
    name: "gordon ramsay hell’s kitchen",
    providerName: "Ezugi",
    playImg: PlayBtn,
  },
  ElementsTheAwakening: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-elements-the-awakening",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Elements-The-Awakening.jpg",
    alt: "elements the awakening",
    name: "elements the awakening",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  GonzosQuest: {
    code: "",
    casino: "Ezugi",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-gonzosquest",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gonzos_Quest.jpg",
    alt: "gonzo's quest",
    name: "gonzo's quest",
    providerName: "Ezugi",
    playImg: PlayBtn,
  },
  FinnAndTheSwirlySpin: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-finn-and-the-swirly-spin",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Finn-and-the-Swirly-Spin.jpg",
    alt: "finn and the swirly spin",
    name: "finn and the swirly spin",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FruitShop: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-fruit-shop",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Fruit-Shop.jpg",
    alt: "fruit shop",
    name: "fruit shop",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DazzleMe: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-dazzle-me",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dazzle-Me.jpg",
    alt: "dazzle me",
    name: "dazzle me",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DoubleStacks: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-double-stacks",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Double-Stacks.jpg",
    alt: "double stacks",
    name: "double stacks",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FairytaleLegendsRedRidingHood: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-fairytale-legends-red-riding-hood",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Fairytale-Legends-Red-Riding-Hood.jpg",
    alt: "fairytale legends red riding hood",
    name: "fairytale legends red riding hood",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Flowers: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-flowers",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Flowers.jpg",
    alt: "flowers",
    name: "flowers",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FruitShopChristmasEdition: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-fruit-shop-christmas-edition",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Fruit-Shop-Christmas-Edition.jpg",
    alt: "fruit shop christmas edition",
    name: "fruit shop christmas edition",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Hotline: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-hotline",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Hotline.jpg",
    alt: "hotline",
    name: "hotline",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Hotline2: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-hotline-2",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Hotline-2.jpg",
    alt: "hotline 2",
    name: "hotline 2",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  TheInvisibleMan: {
    code: "",
    casino: "Ezugi",
    provider: "Ezugi",
    redirectUrl: "/casino/ezg-theinvisibleman",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/The_Invisible_Man.jpg",
    alt: "the invisible man",
    name: "the invisible man",
    providerName: "Ezugi",
    playImg: PlayBtn,
  },
  IrishPotLuck: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-irish-pot-luck",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Irish-Pot-Luck.jpg",
    alt: "irish pot luck",
    name: "irish pot luck",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  FruitSpin: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-fruit-spin",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Fruit-Spin.jpg",
    alt: "fruit spin",
    name: "fruit spin",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  JungleSpiritCallOfTheWild: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-jungle-spirit-call-of-the-wild",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jungle-Spirit-Call-of-the-Wild.jpg",
    alt: "jungle spirit call of the wild",
    name: "jungle spirit call of the wild",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  KoiPrincess: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-koi-princess",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Koi-Princess.jpg",
    alt: "koi princess",
    name: "koi princess",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  DriveMultiplierMayhem: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-drive-multiplier-mayhem",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Drive-Multiplier-Mayhem.jpg",
    alt: "drive multiplier mayhem",
    name: "drive multiplier mayhem",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  RichesOfMidgardLandAndExpand: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-riches-of-midgard-land-and-expand",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Riches-of-Midgard-Land-and-Expand.jpg",
    alt: "riches of midgard land and expand",
    name: "riches of midgard land and expand",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  ScruffyDuck: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-scruffy-duck",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Scruffy-Duck.jpg",
    alt: "scruffy duck",
    name: "scruffy duck",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SecretOfTheStones: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-secret-of-the-stones",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Secret-of-the-Stones.jpg",
    alt: "secret of the stones",
    name: "secret of the stones",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SecretsOfChristmas: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-secrets-of-christmas",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Secrets-of-Christmas.jpg",
    alt: "secrets of christmas",
    name: "secrets of christmas",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SerengetiKings: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-serengeti-kings",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Serengeti-Kings.jpg",
    alt: "serengeti kings",
    name: "serengeti kings",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  SpaceWars: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-space-wars",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Space-Wars.jpg",
    alt: "space wars",
    name: "space wars",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Victorious: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-victorious",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Victorious.jpg",
    alt: "victorious",
    name: "victorious",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  WarlordsCrystalOfPower: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Warlords-Crystal-of-Power.jpg",
    alt: "warlords crystal of power",
    name: "warlords crystal of power",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  WildWater: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-wild-water",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-Water.jpg",
    alt: "wild water",
    name: "wild water",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  WildWorlds: {
    code: "",
    casino: "Net Ent",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgne-wild-worlds",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-Worlds.jpg",
    alt: "wild worlds",
    name: "wild worlds",
    providerName: "Net Ent",
    playImg: PlayBtn,
  },
  Squad4: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-4-squad",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/4-Squad.jpg",
    alt: "4 squad",
    name: "4 squad",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  Families5: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-5-families",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/5-Families.jpg",
    alt: "5 families",
    name: "5 families",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  AgentRoyale: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-agent-royale",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Agent-Royale.jpg",
    alt: "agent royale",
    name: "agent royale",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ArcadeBomb: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-arcade-bomb",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Arcade-Bomb.jpg",
    alt: "arcade bomb",
    name: "arcade bomb",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  Atlantis: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-atlantis",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Atlantis.jpg",
    alt: "atlantis",
    name: "atlantis",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  AztecSpins: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-aztec-spins",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Aztec-Spins.jpg",
    alt: "aztec spins",
    name: "aztec spins",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  CinderellasBall: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-cinderellas-ball",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Cinderella`s-Ball.jpg",
    alt: "cinderellas ball",
    name: "cinderellas ball",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  CirqueDeLaFortune: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-cirque-de-la-fortune",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Cirque-de-la-Fortune.jpg",
    alt: "cirque de la fortune",
    name: "cirque de la fortune",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ClashOfTheBeasts: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-clash-of-the-beasts",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Clash-of-the-Beasts.jpg",
    alt: "clash of the beasts",
    name: "clash of the beasts",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  CrazyGenie: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-crazy-genie",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Crazy-Genie.jpg",
    alt: "crazy genie",
    name: "crazy genie",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  CrystalMirror: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-crystal-mirror",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Crystal-Mirror.jpg",
    alt: "crystal mirror",
    name: "crystal mirror",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DaVincisMystery: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-da-vincis-mystery",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Da-Vinci`s-Mystery.jpg",
    alt: "da vincis mystery",
    name: "da vincis mystery",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DevilsNumber: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-devils-number",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Devil`s-Number.jpg",
    alt: "devils number",
    name: "devils number",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  DiceDiceDice: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-dice-dice-dice",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Dice-Dice-Dice.jpg",
    alt: "dice dice dice",
    name: "dice dice dice",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  FiveStarsPowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-five-stars-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Five-Stars-Power-Reels.jpg",
    alt: "five stars power reels",
    name: "five stars power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GemsGoneWildPowerReels: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-gems-gone-wild-power-reels",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gems-Gone-Wild-Power-Reels.jpg",
    alt: "gems gone wild power reels",
    name: "gems gone wild power reels",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GemsGoneWild: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-gems-gone-wild",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Gems-Gone-Wild.jpg",
    alt: "gems gone wild",
    name: "gems gone wild",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  GoldenLeprechaunMegaways: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-golden-leprechaun-megaways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Golden-Leprechaun-Megaways.jpg",
    alt: "golden leprechaun megaways",
    name: "golden leprechaun megaways",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  HoardOfPoseidon: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-hoard-of-poseidon",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Hoard-of-Poseidon.jpg",
    alt: "hoard of poseidon",
    name: "hoard of poseidon",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  JewelScarabs: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-jewel-scarabs",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Jewel-Scarabs.jpg",
    alt: "jewel scarabs",
    name: "jewel scarabs",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LegendOfAthena: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-legend-of-athena",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Legend-of-Athena.jpg",
    alt: "legend of athena",
    name: "legend of athena",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LuckyHalloween: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-lucky-halloween",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Lucky-Halloween.jpg",
    alt: "lucky halloween",
    name: "lucky halloween",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LuckyLittleDevil: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-lucky-little-devil",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Lucky-Little-Devil.jpg",
    alt: "lucky little devil",
    name: "lucky little devil",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LuckyValentine: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-lucky-valentine",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Lucky-Valentine.jpg",
    alt: "lucky valentine",
    name: "lucky valentine",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  LuckyWizard: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-lucky-wizard",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Lucky-Wizard.jpg",
    alt: "lucky wizard",
    name: "lucky wizard",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  Masquerade: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-masquerade",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Masquerade.jpg",
    alt: "masquerade",
    name: "masquerade",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MayanGods: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mayan-gods",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mayan-Gods.jpg",
    alt: "mayan gods",
    name: "mayan gods",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MegaDragon: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mega-dragon",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mega-Dragon.jpg",
    alt: "mega dragon",
    name: "mega dragon",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MegaJade: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mega-jade",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mega-Jade.jpg",
    alt: "mega jade",
    name: "mega jade",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MegaRise: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-mega-rise",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Mega-Rise.jpg",
    alt: "mega rise",
    name: "mega rise",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  MultiplierRiches: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-multiplier-riches",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Multiplier-Riches.jpg",
    alt: "multiplier riches",
    name: "multiplier riches",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  NinjaWays: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-ninja-ways",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Ninja-Ways.jpg",
    alt: "ninja ways",
    name: "ninja ways",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  PathOfDestiny: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-path-of-destiny",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Path-of-Destiny.jpg",
    alt: "path of destiny",
    name: "path of destiny",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  PersianFortune: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-persian-fortune",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Persian-Fortune.jpg",
    alt: "persian fortune",
    name: "persian fortune",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ReelHeist: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-reel-heist",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Reel-Heist.jpg",
    alt: "reel heist",
    name: "reel heist",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  RobinHoodsWildForest: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-robin-hoods-wild-forest",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Robin-Hood`s-Wild-Forest.jpg",
    alt: "robin hoods wild forest",
    name: "robin hoods wild forest",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  SnowWildAndThe7Features: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Snow-Wild-And-The-7-Features.jpg",
    alt: "snow wild and the 7 features",
    name: "snow wild and the 7 features",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  SylvanSpirits: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-sylvan-spirits",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Sylvan-Spirits.jpg",
    alt: "sylvan spirits",
    name: "sylvan spirits",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  TheGreatestTrainRobbery: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-the-greatest-train-robbery",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/The-Greatest-Train-Robbery.jpg",
    alt: "the greatest train robbery",
    name: "the greatest train robbery",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  TheWildHatter: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-the-wild-hatter",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/The-Wild-Hatter.jpg",
    alt: "the wild hatter",
    name: "the wild hatter",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  ThorsLightning: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-thors-lightning",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Thor`s-Lightning.jpg",
    alt: "thors lightning",
    name: "thors lightning",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  TikiFruits: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-tiki-fruits",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Tiki-Fruits.jpg",
    alt: "tiki fruits",
    name: "tiki fruits",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  TotemLightning: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-totem-lightning",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Totem-Lightning.jpg",
    alt: "totem lightning",
    name: "totem lightning",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  VaultCracker: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-vault-cracker",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Vault-Cracker.jpg",
    alt: "vault cracker",
    name: "vault cracker",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  VaultOfAnubis: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-vault-of-anubis",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Vault-of-Anubis.jpg",
    alt: "vault of anubis",
    name: "vault of anubis",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WellOfWishes: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-well-of-wishes",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Well-of-Wishes.jpg",
    alt: "well of wishes",
    name: "well of wishes",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WildCatsMultiline: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-wild-cats-multiline",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-Cats-Multiline.jpg",
    alt: "wild cats multiline",
    name: "wild cats multiline",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WildOclock: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-wild-oclock",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-O`Clock.jpg",
    alt: "wild oclock",
    name: "wild oclock",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WildWildChest: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-wild-wild-chest",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Wild-Wild-Chest.jpg",
    alt: "wild wild chest",
    name: "wild wild chest",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  WinEscalator: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-win-escalator",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Win-Escalator.jpg",
    alt: "win escalator",
    name: "win-escalator",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
  YucatansMystery: {
    code: "",
    casino: "Red Tiger",
    provider: "Ezugi",
    redirectUrl: "/casino/ezgrt-yucatans-mystery",
    homeUrl: "/slots",
    imgUrl: "./images/slots/newCasino/Yucatan`s-Mystery.jpg",
    alt: "yucatans mystery",
    name: "yucatans mystery",
    providerName: "Red Tiger",
    playImg: PlayBtn,
  },
};
